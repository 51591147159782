







































import { mapState, mapMutations, mapActions } from 'vuex';
import PrintJS from 'print-js';
import { PrinterIcon } from 'vue-feather-icons';
import TheFabButton from '@/components/ui/TheFabButton.vue';
import { IRebox } from '@/store/modules/reboxes/types';
import RepositoryFactory from '@/services/RepositoryFactory';
import { IOrdersRepository } from '@/services/Repositories/OrdersRepository/types';
import PrintMixin from '@/mixins/PrintMixin';

const Factory = new RepositoryFactory();

export default PrintMixin.extend({
  name: 'Labels',
  data() {
    return {
      reboxLabelToPrint: '',
      loading: false,
    };
  },
  components: {
    TheFabButton,
    PrinterIcon,
  },
  computed: {
    ...mapState('globals', ['loader']),
    ...mapState('reboxes', ['reboxes', 'encodedLabelPdf']),
    labelsWereNotPrinted(): boolean {
      const printedLables = this.reboxes.filter(({ printed }: IRebox) => printed === false);
      return printedLables;
    },
    getLabel(): string {
      if (this.reboxLabelToPrint.length) {
        const rebox = this.reboxes.find(
          ({ reboxNr }: IRebox) => reboxNr === this.reboxLabelToPrint,
        );
        return rebox.label || '';
      }
      return '';
    },
    ordersRepository(): IOrdersRepository {
      return Factory.get('orders') as IOrdersRepository;
    },
    orderId(): string {
      const { orderId } = this.$route.params;
      return orderId;
    },
  },
  methods: {
    ...mapMutations('reboxes', ['PRINTED_LABEL', 'CLEAR_REBOXES']),
    ...mapActions('orders', ['addOrder']),
    toggleLoading(): void {
      this.loading = !this.loading;
    },
    async updateOrder(): Promise<void> {
      const { data } = await this.ordersRepository.getById(this.orderId);
      this.addOrder(data);
    },
    printLabel(reboxId: string, label: string): void {
      this.reboxLabelToPrint = reboxId;
      this.print(label);
      this.PRINTED_LABEL(reboxId);
    },
    backToOrders(): void {
      this.CLEAR_REBOXES();
      this.$router.replace({ name: 'allOrders' });
    },
    imageLoaded() {
      return new Promise((resolve) => {
        setTimeout(() => resolve(true), 500);
      });
    },
  },
  mounted() {
    this.updateOrder();
  },
  created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      icons: { icon: icons.processes, action: actions.processes },
    });
  },
});
